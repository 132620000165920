import React from 'react';
import { NavLink } from 'react-router-dom';

const Fixed = () => {
  return (
    <div className="fixed top-0 w-full z-10">
      <div className="flex items-center justify-between px-4 py-2 bg-orange-300">
        <div className="p-2">
          <img className="h-16 md:h-20 w-auto" src="./carry.png" alt="Company Logo" />
        </div>
        <div className="flex items-center space-x-3 md:space-x-6">
          <NavLink to="/" className="text-base md:text-xl font-bold px-2 md:px-4" activeClassName="text-blue-600">Home</NavLink>
          <NavLink to="/about" className="text-base md:text-xl font-bold px-2 md:px-4 hidden md:block" activeClassName="text-blue-600">About Us</NavLink>
          <NavLink to="/shop-by-categories" className="text-base md:text-xl font-bold px-2 md:px-4" activeClassName="text-blue-600">Categories</NavLink>
          <div className="p-2 hidden md:block">
            <img className="h-12 md:h-16 w-auto rounded" src="./jcsgr.png" alt="Company Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fixed;
