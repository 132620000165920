import React from 'react';
import { NavLink } from 'react-router-dom';

const Catagories = () => {
  return (
    <div className="pt-36">
      <div>
        <h1 className="text-5xl font-bold pb-5">Our Services</h1>
      </div>
      <div className="relative pt-10 mx-24">
        <div className="relative flex justify-evenly pt-4 flex-wrap lg:flex-row sm:flex-col items-center">
          <NavLink to="/Mining" className='relative w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 h-auto rounded-2xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8'>
            <img className="rounded-2xl h-72 sm:h-auto" src='./mining2.jpg' alt='mining'/>
            <div className="absolute bottom-0 left-0 w-full bg-black text-center">
              <h1 className="text-2xl text-white py-2">Mining</h1>
            </div>
          </NavLink>

          <NavLink to="/Logistics" className='relative w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 h-auto rounded-2xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8'>
            <img className="rounded-2xl h-72 sm:h-auto" src='./logistics1.avif' alt='logistics'/>
            <div className="absolute bottom-0 left-0 w-full bg-black  text-center">
              <h1 className="text-2xl text-white py-2">Logistics</h1>
            </div>
          </NavLink>

          <NavLink to="/Transportation" className='relative w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 h-auto rounded-2xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8'>
            <img className="rounded-2xl h-72 sm:h-auto" src='./transportation.jpg' alt='transportation'/>
            <div className="absolute bottom-0 left-0 w-full bg-black text-center">
              <h1 className="text-2xl text-white py-2">Transportation</h1>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Catagories;
