import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';
import { UilEnvelope } from '@iconscout/react-unicons';
import { UilPhone } from '@iconscout/react-unicons';
import { UilWhatsappAlt } from '@iconscout/react-unicons';

const Contact = () => {
  return (
    <div>
      <Fixed />
      <div className="pt-12 sm:pt-52 h-64 pb-20 sm:pb-40">
        <h1 className="text-3xl sm:text-5xl h-64 bg-orange-300 text-white pt-12 sm:pt-28 font-bold text-center">
          Contact Us
        </h1>
      </div>

      <div className="flex flex-col justify-center items-center pt-12 sm:pt-64">
        <div className="border-2 rounded-md w-full sm:w-3/5 justify-start flex items-start flex-col p-3 sm:p-5 m-3 sm:m-12">
          <h1 className="text-xl sm:text-2xl font-semibold pl-3 sm:pl-8 pb-2 sm:pb-4">Connect with us:</h1>
          <div className="flex p-2 pl-3 sm:pl-8">
            <UilEnvelope size="32" />
            <p className="pl-2 sm:pl-4 text-lg sm:text-xl font-semibold">contact@jcsglobal.in</p>
          </div>

          <div className="flex p-2 pl-3 sm:pl-8">
            <UilPhone size="32" />
            <p className="pl-2 sm:pl-4 text-lg sm:text-xl font-semibold">91-9090007108</p>
          </div>

          <div className="flex p-2 pl-3 sm:pl-8">
            <UilWhatsappAlt size="32" />
            <p className="pl-2 sm:pl-4 text-lg sm:text-xl font-semibold">91-9090007108</p>
          </div>
        </div>

        <div className="border-2 rounded-md w-full sm:w-3/5 justify-start flex items-start flex-col p-3 sm:p-5 m-3 sm:m-12">
          <h1 className="text-xl sm:text-2xl font-semibold pl-3 sm:pl-8 pb-2 sm:pb-4">Corporate Office:</h1>
          <div className="flex items-start flex-col justify-start pl-3 sm:pl-9">
            <h1 className="text-xl sm:text-2xl font-bold pb-2 sm:pb-4">JCS Global</h1>
            <p className="text-lg sm:text-xl font-semibold pb-1">76-16-53, Urmila Nagar,</p>
            <p className="text-lg sm:text-xl font-semibold pb-1">Bhavanipuram,</p>
            <p className="text-lg sm:text-xl font-semibold pb-1">Vijayawada, A.p .</p>
          </div>
        </div>
      </div>

      <div className="pt-12 sm:pt-48">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
