import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Body from './Body';
import About from './About';
import Contact from './Contact';


const Home = () => {
  const appRouter = createBrowserRouter([
    {
      path: '/',
      element: <Body />
    },
    {
      path: '/about',
      element: <About />
    },
    {
      path: '/shop-by-categories',
      element: <Body />
    },
    {
      path: '/Mining',
      element: <Body />
    },
    {
      path: '/Logistics',
      element: <Body />
    },
    {
      path: '/Transportation',
      element: <Body />
    },
    {
      path:"/contact",
      element:<Contact />
    }
  ]);

  return (
    <RouterProvider router={appRouter}>
      <Body />
    </RouterProvider>
  );
}

export default Home;
