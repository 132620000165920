import React from 'react';

const Connect = () => {
  // Function to handle the contact action based on device type
  const handleContact = () => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobileDevice) {
      // Initiate a phone call
      window.location.href = 'tel:+91 9090007108';
    } else {
      // Open WhatsApp with predefined message
      window.open('https://wa.me/919090007108?text=Hello%20there!', '_blank');
    }
  };

  return (
    <div className="pt-12 sm:pt-32 px-4 sm:px-8">
      <h1 className="text-3xl sm:text-5xl font-bold py-8 sm:py-16 text-center">Connect With Us</h1>
      <div className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-8">
        <button 
          onClick={handleContact} 
          className="bg-orange-300 text-white text-xl sm:text-4xl p-3 sm:p-6 w-full sm:w-1/4 h-16 sm:h-24 rounded-xl hover:scale-105 flex items-center justify-center"
        >
          Call Us
        </button>
        <a 
          href="https://wa.me/919090007108?text=Hello%20there!" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="bg-orange-300 text-white text-xl sm:text-4xl p-3 sm:p-6 w-full sm:w-1/4 h-16 sm:h-24 rounded-xl hover:scale-105 flex items-center justify-center"
        >
          Chat With Us
        </a>
      </div>
    </div>
  );
};

export default Connect;
