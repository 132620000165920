import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Firstimage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleWhatsAppClick = () => {
    window.location.href = 'https://wa.me/919090007108?text=Hello%20there!';
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    appendDots: dots => (
      <div className="absolute bottom-5 left-0 right-0 flex justify-center pt-5">
        <ul className="flex justify-center">{dots}</ul>
      </div>
    ),
    customPaging: i => <div className="h-3 w-3 mx-2 bg-slate-700 rounded-full" />,
  };

  return (
    <div className="pt-12 sm:pt-20">
      <Slider {...settings}>
        {/* Mining Slide */}
        <div className="relative w-full h-auto">
          <img className="w-full h-auto" src="./mining.jpg" alt="Slide 1" />
          <div className={`absolute top-${isMobile ? '2' : '0'} left-0 bottom-0 w-full sm:w-2/5 h-full flex flex-col justify-center`}>
            <div className={`bg-white bg-opacity-35 p-3 sm:p-5 rounded-xl py-8 sm:py-16 ${isMobile ? 'text-sm' : ''}`}>
              <h1 className={`text-cyan-800 ${isMobile ? 'text-xl' : 'text-7xl'} font-bold`}>Mining</h1>
              <p className={`text-gray-700 ${isMobile ? 'text-base' : 'text-3xl'} pt-4 sm:pt-10`}>{"Discovering Hidden Gems: The World of Mining"}</p>
              <div className="pt-8 sm:pt-12">
                <button onClick={handleWhatsAppClick} className={`bg-gradient-to-r from-pink-300 via-blue-500 to-sky-300 p-2 sm:p-3 rounded-lg sm:rounded-2xl hover:scale-105 ${isMobile ? 'text-base' : 'text-xl'} text-white`}>Get Service</button>
              </div>
            </div>
          </div>
        </div>
        {/* Transportation Slide */}
        <div className="relative w-full h-auto">
          <img className="w-full h-auto" src="./transport1.avif" alt="Slide 2" />
          <div className={`absolute top-${isMobile ? '2' : '0'} right-0 bottom-0 w-full sm:w-2/5 h-full flex flex-col justify-center`}>
            <div className={`bg-white bg-opacity-35 p-3 sm:p-5 rounded-xl py-8 sm:py-16 ${isMobile ? 'text-sm' : ''}`}>
              <h1 className={`text-indigo-800 ${isMobile ? 'text-xl' : 'text-7xl'} font-bold`}>Transportation</h1>
              <p className={`text-gray-700 ${isMobile ? 'text-base' : 'text-3xl'} pt-4 sm:pt-10`}>{"Efficiently Moving Forward: The World of Transportation"}</p>
              <div className="pt-8 sm:pt-12">
                <button onClick={handleWhatsAppClick} className={`bg-gradient-to-r from-pink-300 via-blue-500 to-sky-300 p-2 sm:p-3 rounded-lg sm:rounded-2xl hover:scale-105 ${isMobile ? 'text-base' : 'text-xl'} text-white`}>Get Service</button>
              </div>
            </div>
          </div>
        </div>
        {/* Logistics Slide */}
        <div className="relative w-full h-auto">
          <img className="w-full h-auto" src="./logistics.jpg" alt="Slide 3" />
          <div className={`absolute top-${isMobile ? '2' : '0'} left-0 bottom-0 w-full sm:w-2/5 h-full flex flex-col justify-center`}>
            <div className={`bg-white bg-opacity-35 p-3 sm:p-5 rounded-xl py-8 sm:py-16 ${isMobile ? 'text-sm' : ''}`}>
              <h1 className={`text-cyan-800 ${isMobile ? 'text-xl' : 'text-7xl'} font-bold`}>Logistics</h1>
              <p className={`text-gray-700 ${isMobile ? 'text-base' : 'text-3xl'} pt-4 sm:pt-10`}>{"Streamlining Supply Chains: The World of Logistics"}</p>
              <div className="pt-8 sm:pt-12">
                <button onClick={handleWhatsAppClick} className={`bg-gradient-to-r from-pink-300 via-blue-500 to-sky-300 p-2 sm:p-3 rounded-lg sm:rounded-2xl hover:scale-105 ${isMobile ? 'text-base' : 'text-xl'} text-white`}>Get Service</button>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Firstimage;
