import React from 'react'
import Fixed from "./Fixed";
import Footer from "./Footer";

const About = () => {
  return (
    <div>
    <Fixed />
    <div className="pt-52 h-64">
        <h1 className="text-5xl h-64 bg-orange-300 text-white pt-28 font-bold"> About Us</h1>
    </div>
    
    <div className="pt-56">
        <p className="pt-28 m-14 text-2xl font-semibold">Our mission is to build the most trusted B2B e-commerce platform that helps SMEs unlock their potential by transforming the way they do their business. We empower contractors and retailers to procure high-quality construction raw materials from top brands at competitive prices, with easy access to finance and a smooth delivery experience. Our platform also provides suppliers with the opportunity to grow their market and increase capacity utilization. We are a technology-first brand that is digitizing the entire ecosystem, making it more efficient, transparent, and accessible for businesses.</p>
    </div>


    <div>
    <div className="pt-44">
        <h1 className="text-4xl font-semibold">Our LeaderShip Team</h1>
    </div>
    </div>


    

    <div className="pt-52">
       <Footer />
    </div>
    
</div>
  )
}

export default About;