import React from 'react';
import Marquee from "react-fast-marquee";

const Brands = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center pt-40 sm:pt-20">
      <div className="w-full flex flex-col">
        <div className="flex flex-col text-center mb-14 pb-8 space-y-2">
          <span className="text-5xl font-bold sm:text-3xl">
            Top Brands
          </span>
        </div>
        <Marquee className="">
          <img className="h-16 w-auto px-6 sm:h-12 sm:px-3" src='./carry.png' alt='Brand 1' />
          <img className="h-16 w-auto px-6 sm:h-12 sm:px-3" src='./jcsgr.png' alt='Brand 2' />
          <img className="h-16 w-auto px-6 sm:h-12 sm:px-3" src='./jtv.png' alt='Brand 3' />
          <img className="h-24 w-auto px-2 sm:h-16 sm:px-1" src='./jfarm.jpg' alt='Brand 4' />
          <img className="h-16 w-auto px-2 sm:h-12 sm:px-1" src='./jpay1.png' alt='Brand 5' />
          <img className="h-16 w-auto sm:h-12" src='./MainLogo.png' alt='Brand 6' />
          <img className="h-24 w-auto px-6 sm:h-16 sm:px-3" src='./sunotal.png' alt='Brand 7' />
          <img className="h-24 w-auto px-2 sm:h-16 sm:px-1" src='./jfc.jpg' alt='Brand 8' />
        </Marquee>
      </div>
    </div>
  );
};

export default Brands;
