import React, { useState, useEffect } from 'react';
import { BsInstagram, BsTwitterX, BsFacebook, BsLinkedin } from "react-icons/bs";
import { NavLink } from 'react-router-dom';

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check if the screen size is less than 700px
  const isMobile = windowWidth < 850;

  // Define styles for responsive adjustments
  const responsiveTextStyle = isMobile ? 'text-lg' : 'text-xl';
  const responsiveIconStyle = isMobile ? 'w-10 h-10' : 'w-12 h-12';

  return (
    <div className="bg-orange-300 mt-20">
      <div className="flex items-center justify-center pt-20">
        <img className="h-16 w-auto" src="./carry.png" alt="" />
      </div>
      <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-center pt-16`}>
        <NavLink to ="/about" className={`px-6  ${responsiveTextStyle} ${isMobile ? 'pb-6' : ''}`}>About Us</NavLink>
        <NavLink to ="/contact" className={`px-6  ${responsiveTextStyle} ${isMobile ? 'pb-6' : ''}`}>Contact Us</NavLink>
        <p className={`px-6  ${responsiveTextStyle} ${isMobile ? 'pb-6' : ''}`}>Terms of Use</p>
      </div>

      <div className="flex justify-center space-x-10 pt-14 pb-14 border-b">
      <a href="https://www.facebook.com/jcserviceshub" target="_blank" rel="noopener noreferrer" className="card shadow-2xl">
        <BsFacebook className={`hover:text-[#2f29d6] ${responsiveIconStyle}`} />
      </a>
      <a href="https://www.instagram.com/jcs.hub/" target="_blank" rel="noopener noreferrer" className="card shadow-2xl">
        <BsInstagram className={`hover:text-[#d62976] ${responsiveIconStyle}`} />
      </a>
      <a href="https://www.linkedin.com/company/jcs-hub/" target="_blank" rel="noopener noreferrer" className="card shadow-2xl">
        <BsLinkedin className={`hover:text-[#0077b5] ${responsiveIconStyle}`} />
      </a>
      <a href="https://x.com/jcshub?lang=en" target="_blank" rel="noopener noreferrer" className="card shadow-2xl">
        <BsTwitterX className={`hover:text-[#00acee] ${responsiveIconStyle}`} />
      </a>
    </div>

      <div className="py-10">
        <h1 className={`${responsiveTextStyle}`}>©2020 JCS Global. All Rights Reserved | Caution Notice</h1>
      </div>
      <div className="flex justify-center pb-10">
        <h1 className={`text-2xl  px-6 ${responsiveTextStyle}`}>JCS Group</h1>
        <h1 className={`text-2xl  px-6 ${responsiveTextStyle}`}>Carry Carter</h1>
        <h1 className={`text-2xl  px-6 ${responsiveTextStyle}`}>JCS Hub</h1>
      </div>
    </div>
  );
};

export default Footer;
